//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions, mapMutations } from 'vuex';
import KModal from 'kolibri.coreVue.components.KModal';
import KRadioButton from 'kolibri.coreVue.components.KRadioButton';

export default {
  name: 'FacilityModal',
  components: {
    KModal,
    KRadioButton,
  },
  data() {
    return {
      // currentFacilityId uses session, with is anonymous in sign-in-page
      selectedFacility: this.$store.state.facilityId,
    };
  },
  computed: {
    ...mapGetters(['facilities']),
  },
  methods: {
    ...mapActions(['getFacilityConfig']),
    ...mapMutations({
      setFacilityId: 'SET_FACILITY_ID',
      setLoginError: 'CORE_SET_LOGIN_ERROR',
    }),
    emitClose() {
      this.$emit('close');
    },
    submitAndClose() {
      this.setFacilityId(this.selectedFacility);
      this.setLoginError('');
      this.getFacilityConfig(this.selectedFacility).then(this.emitClose);
    },
  },
  $trs: {
    facilitySelectionPrompt: 'Which facility do you want to sign in to?',
    submitFacilitySelectionButtonPrompt: 'Select',
    facilitySelectionModalHeader: 'Select a facility',
    close: 'Close',
  },
};

