//
//
//
//
//
//
//


import LanguageSwitcherList from 'kolibri.coreVue.components.LanguageSwitcherList';

export default {
  name: 'LanguageSwitcherFooter',
  components: {
    LanguageSwitcherList,
  },
};

