//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { validateUsername } from 'kolibri.utils.validators';
import KButton from 'kolibri.coreVue.components.KButton';
import KTextbox from 'kolibri.coreVue.components.KTextbox';
import KSelect from 'kolibri.coreVue.components.KSelect';
import PrivacyInfoModal from 'kolibri.coreVue.components.PrivacyInfoModal';
import { ERROR_CONSTANTS } from 'kolibri.coreVue.vuex.constants';
import { PageNames } from '../constants';
import LanguageSwitcherFooter from './LanguageSwitcherFooter';

export default {
  name: 'SignUpPage',
  $trs: {
    createAccount: 'Create an account',
    name: 'Full name',
    username: 'Username',
    password: 'Password',
    reEnterPassword: 'Re-enter password',
    passwordMatchError: 'Passwords do not match',
    genericError: 'Something went wrong during sign up',
    usernameAlphaNumError: 'Username can only contain letters, numbers, and underscores',
    usernameAlreadyExistsError: 'An account with that username already exists',
    logIn: 'Sign in',
    kolibri: 'Kolibri',
    finish: 'Finish',
    facility: 'Facility',
    required: 'This field is required',
    documentTitle: 'User Sign Up',
    privacyLink: 'Usage and privacy in Kolibri',
  },
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    KButton,
    KTextbox,
    KSelect,
    LanguageSwitcherFooter,
    PrivacyInfoModal,
  },
  data: () => ({
    name: '',
    username: '',
    password: '',
    confirmedPassword: '',
    selectedFacility: {},
    nameBlurred: false,
    usernameBlurred: false,
    passwordBlurred: false,
    confirmedPasswordBlurred: false,
    facilityBlurred: false,
    formSubmitted: false,
    privacyModalVisible: false,
  }),
  computed: {
    ...mapGetters(['facilities', 'session']),
    ...mapState('signUp', ['errors', 'busy']),
    signInPage() {
      return { name: PageNames.SIGN_IN };
    },
    facilityList() {
      return this.facilities.map(facility => ({
        label: facility.name,
        value: facility.id,
      }));
    },
    nameIsInvalidText() {
      if (this.nameBlurred || this.formSubmitted) {
        if (this.name === '') {
          return this.$tr('required');
        }
      }
      return '';
    },
    nameIsInvalid() {
      return Boolean(this.nameIsInvalidText);
    },
    usernameDoesNotExistYet() {
      if (this.errors.includes(ERROR_CONSTANTS.USERNAME_ALREADY_EXISTS)) {
        return false;
      }
      return true;
    },
    usernameIsInvalidText() {
      if (this.usernameBlurred || this.formSubmitted) {
        if (this.username === '') {
          return this.$tr('required');
        }
        if (!validateUsername(this.username) || this.errors.includes(ERROR_CONSTANTS.INVALID)) {
          return this.$tr('usernameAlphaNumError');
        }
        if (!this.usernameDoesNotExistYet) {
          return this.$tr('usernameAlreadyExistsError');
        }
      }
      return '';
    },
    usernameIsInvalid() {
      return Boolean(this.usernameIsInvalidText);
    },
    passwordIsInvalidText() {
      if (this.passwordBlurred || this.formSubmitted) {
        if (this.password === '') {
          return this.$tr('required');
        }
      }
      return '';
    },
    passwordIsInvalid() {
      return Boolean(this.passwordIsInvalidText);
    },
    confirmedPasswordIsInvalidText() {
      if (this.confirmedPasswordBlurred || this.formSubmitted) {
        if (this.confirmedPassword === '') {
          return this.$tr('required');
        }
        if (this.confirmedPassword !== this.password) {
          return this.$tr('passwordMatchError');
        }
      }
      return '';
    },
    confirmedPasswordIsInvalid() {
      return Boolean(this.confirmedPasswordIsInvalidText);
    },
    noFacilitySelected() {
      return !this.selectedFacility.value;
    },
    facilityIsInvalidText() {
      if (this.facilityBlurred || this.formSubmitted) {
        if (this.noFacilitySelected) {
          return this.$tr('required');
        }
      }
      return '';
    },
    facilityIsInvalid() {
      return Boolean(this.facilityIsInvalidText);
    },
    formIsValid() {
      return (
        !this.nameIsInvalid &&
        !this.usernameIsInvalid &&
        !this.passwordIsInvalid &&
        !this.confirmedPasswordIsInvalid &&
        !this.facilityIsInvalid
      );
    },
  },
  beforeMount() {
    if (this.facilityList.length === 1) {
      this.selectedFacility = this.facilityList[0];
    }
  },
  methods: {
    ...mapActions('signUp', ['signUpNewUser']),
    ...mapMutations('signUp', {
      resetSignUpState: 'RESET_STATE',
    }),
    signUp() {
      this.formSubmitted = true;
      const canSubmit = this.formIsValid && !this.busy;
      if (canSubmit) {
        this.signUpNewUser({
          facility: this.selectedFacility.value,
          full_name: this.name,
          username: this.username,
          password: this.password,
        });
      } else {
        this.focusOnInvalidField();
      }
    },
    focusOnInvalidField() {
      if (this.nameIsInvalid) {
        this.$refs.name.focus();
      } else if (this.usernameIsInvalid) {
        this.$refs.username.focus();
      } else if (this.passwordIsInvalid) {
        this.$refs.password.focus();
      } else if (this.confirmedPasswordIsInvalid) {
        this.$refs.confirmedPassword.focus();
      }
    },
  },
};

